import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

export class WebsocketProvider<T> {
  private readonly websocketProtocol: string;
  private readonly websocketSecureProtocol: string;

  constructor() {
    this.websocketProtocol = 'ws';
    this.websocketSecureProtocol = 'wss';
  }

  public provide(host: string, portNumber?: number): WebSocketSubject<T> {
    return webSocket<T>(this.getWebsocketUrl(host, null, portNumber));
  }

  public provideWithSecureProtocol(host: string, token: string | null, portNumber?: number): WebSocketSubject<T> {
    return webSocket<T>(this.getWebsocketUrl(host, token, portNumber, true));
  }

  private getWebsocketUrl(host: string, token: string | null, portNumber?: number, isSecureProtocol?: boolean): string {
    const protocol: string = isSecureProtocol ? this.websocketSecureProtocol : this.websocketProtocol;

    let url: string = `${protocol}://${host}`;

    if (portNumber) {
      url += `:${portNumber}`;
    }

    if (token) {
      url += `?token=${token}`;
    }

    return url;
  }
}
