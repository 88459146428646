import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  iif,
  Observable,
  retry,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  throwError,
  timer,
} from 'rxjs';

import { SpectrophotometerSettingsModel } from '../../../settings/models/spectrophotometer-settings/spectrophotometer-settings.model';
import { SettingsService } from '../../../settings/services/settings/settings.service';
import { SpectrophotometerEnum } from '../../enums/spectrophotometer/spectrophotometer.enum';
import { SpectrophotometerConnectionStatusModel } from '../../models/spectrophotometer-connection-status/spectrophotometer-connection-status.model';
import { SpectrophotometerMeasurementModel } from '../../models/spectrophotometer-measurement/spectrophotometer-measurement.model';
import { SpectrophotometerTypeModel } from '../../models/spectrophotometer-type/spectrophotometer-type.model';
import { ConnectorService } from '../connector/connector.service';
import { SpectrophotometerConnectionDeviceDetailsModel } from '../../models/spectrophotometer-connection-device-info/spectrophotometer-connection-device-info.model';

export class SpectrophotometerDeviceConnectionService {
  public isSpectrophotometerConnected$: Observable<boolean>;
  public spectrophotometerDeviceDetails$: Observable<SpectrophotometerConnectionDeviceDetailsModel | null>;
  public isConnectionLoading$: Observable<boolean>;

  private isSpectrophotometerConnectedSource$: Subject<boolean>;
  private spectrophotometerDeviceDetailsSource$: Subject<SpectrophotometerConnectionDeviceDetailsModel | null>;
  private isSpectrophotometerConnectedSubscription?: Subscription;
  private isConnectionLoadingSource$: BehaviorSubject<boolean>;
  private readonly pollingTimeMs: number;

  constructor(
    private settingsService: SettingsService,
    private connectorService: ConnectorService,
    private isConnectorConnected$: Observable<boolean>,
    private translateService: TranslateService,
    private unsubscribe$: Subject<void>
  ) {
    this.pollingTimeMs = 5000;

    this.isSpectrophotometerConnectedSource$ = new BehaviorSubject(false);
    this.isSpectrophotometerConnected$ = this.isSpectrophotometerConnectedSource$.asObservable();

    this.spectrophotometerDeviceDetailsSource$ = new BehaviorSubject<SpectrophotometerConnectionDeviceDetailsModel | null>(null);
    this.spectrophotometerDeviceDetails$ = this.spectrophotometerDeviceDetailsSource$.asObservable();

    this.isConnectionLoadingSource$ = new BehaviorSubject<boolean>(false);
    this.isConnectionLoading$ = this.isConnectionLoadingSource$.asObservable().pipe(distinctUntilChanged());
  }

  public initSpectrophotometerConnectionObserver(): void {
    if (this.isSpectrophotometerConnectedSubscription) {
      this.stopSpectrophotometerConnectionObserver();
    }

    this.isConnectionLoadingSource$.next(true);

    this.isSpectrophotometerConnectedSubscription = this.settingsService
      .getSpectrophotometerSettings()
      .pipe(
        filter((settings: SpectrophotometerSettingsModel) => {
          return settings.isEnabled;
        }),
        finalize(() => {
          this.isConnectionLoadingSource$.next(false);
        }),
        switchMap(() => this.startPollingForSpectrophotometerStatus()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (isConnected: SpectrophotometerConnectionStatusModel) => {
          this.spectrophotometerDeviceDetailsSource$.next(isConnected.deviceDetails);
          this.isSpectrophotometerConnectedSource$.next(isConnected.isConnected);
          this.isConnectionLoadingSource$.next(false);
        },
        error: () => {
          this.isSpectrophotometerConnectedSource$.next(false);
          this.spectrophotometerDeviceDetailsSource$.next(null);
        },
      });
  }

  public stopSpectrophotometerConnectionObserver(): void {
    this.isSpectrophotometerConnectedSource$.next(false);
    this.spectrophotometerDeviceDetailsSource$.next(null);
    this.isSpectrophotometerConnectedSubscription?.unsubscribe();
    this.isSpectrophotometerConnectedSubscription = undefined;
  }

  public getSpectrophotometerMeasurements(device: SpectrophotometerTypeModel): Observable<Array<SpectrophotometerMeasurementModel>> {
    return this.isSpectrophotometerConnected$.pipe(
      take(1),
      switchMap((isSpectrophotometerConnected: boolean) => {
        return iif(
          () => isSpectrophotometerConnected,
          this.connectorService.getSpectrophotometerMeasurements(device),
          this.throwSpectrophotometerNotConnectedError()
        );
      })
    );
  }

  public clearSpectrophotometerMeasurements(device: SpectrophotometerTypeModel): Observable<void> {
    return this.isSpectrophotometerConnected$.pipe(
      take(1),
      switchMap((isSpectrophotometerConnected: boolean) => {
        return iif(
          () => isSpectrophotometerConnected,
          this.connectorService.clearSpectrophotometerMeasurements(device),
          this.throwSpectrophotometerNotConnectedError()
        );
      })
    );
  }

  private throwSpectrophotometerNotConnectedError(): Observable<never> {
    return throwError(() => new Error(this.translateService.instant('spectrophotometer.connection.notConnectedError') as string));
  }

  private startPollingForSpectrophotometerStatus(): Observable<SpectrophotometerConnectionStatusModel> {
    return timer(0, this.pollingTimeMs).pipe(
      switchMap(() => this.isConnectorConnected$.pipe(first())),
      filter((isConnected: boolean) => isConnected),
      switchMap(() =>
        this.connectorService.checkSpectrophotometerConnection(SpectrophotometerEnum.Xrite).pipe(
          catchError((error: HttpErrorResponse) => {
            this.isSpectrophotometerConnectedSource$.next(false);
            this.spectrophotometerDeviceDetailsSource$.next(null);

            throw error;
          })
        )
      ),
      retry({
        delay: this.pollingTimeMs,
      })
    );
  }
}
