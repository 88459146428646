import { AssignedDeviceDto } from '../../dtos/assigned-com-device/assigned-com-device.dto';
import { AssignedComDeviceModel } from '../../models/assigned-com-device/assigned-com-device.model';

export class AssignedComDeviceCreator {
  public static create(dto: AssignedDeviceDto): AssignedComDeviceModel {
    return {
      assignedDevice: dto.assignedDevice,
      comName: dto.comName,
    };
  }
}
