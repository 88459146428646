import { SpectrophotometerMeasurementDto } from '../../dtos/spectrophotometer-measurement/spectrophotometer-measurement.dto';
import { SpectrophotometerMeasurementModel } from '../../models/spectrophotometer-measurement/spectrophotometer-measurement.model';
import { SpectrophotometerMeasurementImageItemDto } from '../../dtos/spectrophotometer-measurement-image-item/spectrophotometer-measurement-image-item.dto';
import { SpectrophotometerMeasurementImageItemModel } from '../../models/spectrophotometer-measurement-image-item/spectrophotometer-measurement-image-item.model';

export class SpectrophotometerMeasurementCreator {
  public static create(dto: SpectrophotometerMeasurementDto): SpectrophotometerMeasurementModel {
    return {
      colorSpectra: dto.colorSpectra,
      deviceSerialNumber: dto.DeviceSerialNumber,
      deviceModel: dto.DeviceModel,
      firmwareVersion: dto.FirmwareVersion,
      jobId: dto.JobId,
      jobName: dto.JobName,
      images: dto.images.map((dto: SpectrophotometerMeasurementImageItemDto) => SpectrophotometerMeasurementCreator.mapImage(dto)),
    };
  }

  private static mapImage(dto: SpectrophotometerMeasurementImageItemDto): SpectrophotometerMeasurementImageItemModel {
    return {
      geometry: dto.geometry,
      base64ImageData: dto.base64ImageData,
      type: dto.type,
      metrics: {
        metricC: dto.metrics.metric_C,
        metricSG: dto.metrics.metric_SG,
        metricSD: dto.metrics.metric_SD,
        metricCV: dto.metrics.metric_CV,
        metricSC: dto.metrics.metric_SC,
        metricCO: dto.metrics.metric_CO,
        metricDC: dto.metrics.metric_DC,
        textureSdkVersion: dto.metrics.TextureSdkVersion,
      },
    };
  }
}
