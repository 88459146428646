import { AssignedComDeviceListDto } from '../../dtos/assigned-com-device-list/assigned-com-device-list.dto';
import { SupportedDeviceNameModel } from '../../models/supported-device-name/supported-device-name.model';

export class ConnectorAssignedComDeviceDtoCreator {
  public static create(comPort: string, comDevice: SupportedDeviceNameModel): AssignedComDeviceListDto {
    return {
      configuration: [
        {
          comName: comPort,
          assignedDevice: comDevice,
        },
      ],
    };
  }
}
