import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConnectorUrlUtil } from '../../../core/utils/connector-url/connector-url.util';
import { ConnectorUrlGroupEnum } from '../../../core/enums/connector-group/connector-group.enum';
import { AssignedComDeviceListDto } from '../../dtos/assigned-com-device-list/assigned-com-device-list.dto';
import { SupportedComDeviceTypeDto } from '../../dtos/supported-com-device-type/supported-com-device-type.dto';
import { WebsocketRequestDto } from '../../dtos/websocket-request/websocket-request.dto';

@Injectable({
  providedIn: 'root',
})
export class ComPortHttpService {
  constructor(private httpClient: HttpClient) {}

  public listComPorts(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(`${ConnectorUrlUtil.getConnectorUrl('list', ConnectorUrlGroupEnum.com)}`);
  }

  public supportedComDevicesList(): Observable<Array<SupportedComDeviceTypeDto>> {
    return this.httpClient.get<Array<SupportedComDeviceTypeDto>>(
      `${ConnectorUrlUtil.getConnectorUrl('supported_com_devices', ConnectorUrlGroupEnum.com)}`
    );
  }

  public assignComDevice(assignComDeviceListDto: AssignedComDeviceListDto): Observable<void> {
    return this.httpClient.post<void>(
      `${ConnectorUrlUtil.getConnectorUrl('assigned_devices', ConnectorUrlGroupEnum.com)}`,
      assignComDeviceListDto
    );
  }

  public getAssignedComDevicesList(): Observable<AssignedComDeviceListDto> {
    return this.httpClient.get<AssignedComDeviceListDto>(
      `${ConnectorUrlUtil.getConnectorUrl('assigned_devices', ConnectorUrlGroupEnum.com)}`
    );
  }

  public requestWebsocketStream(comPort: string): Observable<WebsocketRequestDto> {
    return this.httpClient.post<WebsocketRequestDto>(
      `${ConnectorUrlUtil.getConnectorUrl(`request_stream/${comPort}`, ConnectorUrlGroupEnum.com)}`,
      null
    );
  }
}
