import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  Observable,
  retry,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';

import { environment } from '../../../../environments/environment';
import { SettingsService } from '../../../settings/services/settings/settings.service';
import { WebsocketProvider } from '../../../shared/providers/websocket/websocket.provider';
import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { BarcodeScannerDataTypeCreator } from '../../creators/barcode-scanner-data-type/barcode-scanner-data-type.creator';
import { ScaleDataTypeCreator } from '../../creators/scale-data-type-creator/scale-data-type.creator';
import { BarcodeScannerDataTypeDto } from '../../dtos/barcode-scanner-data-type/barcode-scanner-data-type.dto';
import { ScaleDataTypeDto } from '../../dtos/scale-data-type/scale-data-type.dto';
import { ScaleCommandsEnum } from '../../enums/scale-commands/scale-commands.enum';
import { BarcodeScannerDataTypeModel } from '../../models/barcode-scanner-data-type/barcode-scanner-data-type.model';
import { ScaleDataTypeModel } from '../../models/scale-data-type/scale-data-type.model';
import { StationIdModel } from '../../models/station-id/station-id.model';
import { ConnectorService } from '../connector/connector.service';
import { SpectrophotometerDeviceConnectionService } from '../spectrophotometer-device-connection/spectrophotometer-device-connection.service';
import { WebsocketDeviceConnectionService } from '../websocket-device-connection/websocket-device-connection.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentConnectionService implements OnDestroy {
  public isConnectorConnected$: Observable<boolean>;
  public scaleDevice: WebsocketDeviceConnectionService<ScaleDataTypeDto, ScaleDataTypeModel, ScaleCommandsEnum>;
  public barcodeScannerDevice: WebsocketDeviceConnectionService<BarcodeScannerDataTypeDto, BarcodeScannerDataTypeModel>;
  public spectrophotometerDevice: SpectrophotometerDeviceConnectionService;
  public stationId: string | null;

  private pollingTimeMs: number;
  private isConnectorConnectedSource$: Subject<boolean>;
  private unsubscribe$: Subject<void>;

  constructor(
    private connectorService: ConnectorService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private currentUserService: CurrentUserService
  ) {
    this.pollingTimeMs = 5000;
    this.isConnectorConnectedSource$ = new BehaviorSubject(false);
    this.isConnectorConnected$ = this.isConnectorConnectedSource$.asObservable();
    this.unsubscribe$ = new Subject();

    this.scaleDevice = this.initScaleDevice();
    this.barcodeScannerDevice = this.initBarcodeScannerDevice();
    this.spectrophotometerDevice = this.initSpectrophotometerDevice();
    this.stationId = null;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initCurrentConnectionService(): void {
    if (!environment.connector.enabled || !environment.connector.url) {
      return;
    }

    this.currentUserService
      .getCurrentUser()
      .pipe(filter((currentUser: CurrentUserModel | null) => !!currentUser))
      .subscribe(() => {
        this.initConnectorConnectionObserver();

        this.spectrophotometerDevice.initSpectrophotometerConnectionObserver();
        this.scaleDevice.initConnection();
        this.barcodeScannerDevice.initConnection();
        this.initStationId();
      });
  }

  public initConnectorConnectionObserver(): void {
    timer(0, this.pollingTimeMs)
      .pipe(
        switchMap(() => {
          return this.connectorService.getVersion().pipe(
            catchError((error: HttpErrorResponse) => {
              this.isConnectorConnectedSource$.next(false);

              throw error;
            })
          );
        }),
        retry({
          delay: this.pollingTimeMs,
        }),
        finalize(() => this.isConnectorConnectedSource$.next(false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.isConnectorConnectedSource$.next(true);
      });
  }

  public stopObservingConnectorConnections(): void {
    this.spectrophotometerDevice.stopSpectrophotometerConnectionObserver();
    this.scaleDevice.stopWebsocketDeviceConnectionObserver();

    this.unsubscribe$.next();
  }

  public clearStationId(): void {
    this.stationId = null;
  }

  private initStationId(): void {
    this.isConnectorConnected$
      .pipe(
        distinctUntilChanged(),
        filter((isConnectorConnected: boolean) => {
          return isConnectorConnected;
        }),
        switchMap(() => this.connectorService.getStationId()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ stationId }: StationIdModel) => {
        this.stationId = stationId;
      });
  }

  private initScaleDevice(): WebsocketDeviceConnectionService<ScaleDataTypeDto, ScaleDataTypeModel, ScaleCommandsEnum> {
    return new WebsocketDeviceConnectionService<ScaleDataTypeDto, ScaleDataTypeModel, ScaleCommandsEnum>(
      this.settingsService.getScaleSettings(),
      this.connectorService,
      new WebsocketProvider<ScaleDataTypeDto>(),
      ScaleDataTypeCreator
    );
  }

  private initBarcodeScannerDevice(): WebsocketDeviceConnectionService<BarcodeScannerDataTypeDto, BarcodeScannerDataTypeModel> {
    return new WebsocketDeviceConnectionService<BarcodeScannerDataTypeDto, BarcodeScannerDataTypeModel>(
      this.settingsService.getBarcodeScannerSettings(),
      this.connectorService,
      new WebsocketProvider<BarcodeScannerDataTypeDto>(),
      BarcodeScannerDataTypeCreator
    );
  }

  private initSpectrophotometerDevice(): SpectrophotometerDeviceConnectionService {
    return new SpectrophotometerDeviceConnectionService(
      this.settingsService,
      this.connectorService,
      this.isConnectorConnected$,
      this.translateService,
      this.unsubscribe$
    );
  }
}
