import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { PrinterListItemDto } from '../../dtos/printer-list-item/printer-list-item.dto';
import { PrinterListItemModel } from '../../models/printer-list-item/printer-list-item.model';

export class PrinterListItemCreator {
  public static create(dto: PrinterListItemDto): PrinterListItemModel {
    return {
      name: dto,
    };
  }

  public static toDropdownOption(item: PrinterListItemModel): DropdownOptionModel<string> {
    return {
      label: item.name,
      value: item.name,
    };
  }
}
