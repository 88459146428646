import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConnectorUrlGroupEnum } from '../../../core/enums/connector-group/connector-group.enum';
import { ConnectorUrlUtil } from '../../../core/utils/connector-url/connector-url.util';
import { SpectrophotometerConnectionStatusDto } from '../../dtos/spectrophotometer-connection-status/spectrophotometer-connection-status.dto';
import { SpectrophotometerMeasurementDto } from '../../dtos/spectrophotometer-measurement/spectrophotometer-measurement.dto';
import { SpectrophotometerTypeModel } from '../../models/spectrophotometer-type/spectrophotometer-type.model';

@Injectable({
  providedIn: 'root',
})
export class SpectrophotometerHttpService {
  constructor(private httpClient: HttpClient) {}

  public checkConnection(device: SpectrophotometerTypeModel): Observable<SpectrophotometerConnectionStatusDto> {
    return this.httpClient.get<SpectrophotometerConnectionStatusDto>(
      `${ConnectorUrlUtil.getConnectorUrl(`check_connected?device=${device}`, ConnectorUrlGroupEnum.spectrophotometers)}`
    );
  }

  public getMeasurements(device: SpectrophotometerTypeModel): Observable<Array<SpectrophotometerMeasurementDto>> {
    return this.httpClient.get<Array<SpectrophotometerMeasurementDto>>(
      `${ConnectorUrlUtil.getConnectorUrl(`get_measurements?device=${device}`, ConnectorUrlGroupEnum.spectrophotometers)}`
    );
  }

  public clearMeasurements(device: SpectrophotometerTypeModel): Observable<void> {
    return this.httpClient.post<void>(
      `${ConnectorUrlUtil.getConnectorUrl(`clear_measurements?device=${device}`, ConnectorUrlGroupEnum.spectrophotometers)}`,
      null
    );
  }
}
