import { SupportedComDeviceTypeDto } from '../../dtos/supported-com-device-type/supported-com-device-type.dto';
import { SupportedComDeviceTypeNamesModel } from '../../models/supported-com-device-type-names/supported-com-device-type-names.model';

export class SupportedComDeviceTypeCreator {
  public static create(dto: SupportedComDeviceTypeDto): SupportedComDeviceTypeNamesModel {
    return {
      name: dto.name,
      type: dto.type,
    } as SupportedComDeviceTypeNamesModel;
  }
}
