import { PrintPdfBinaryDto } from '../../dtos/print-pdf-binary/print-pdf-binary.dto';
import { PrintPdfBinaryModel } from '../../models/print-pdf-binary/print-pdf.model';

export class PrintPdfBinaryDtoCreator {
  public static create(model: PrintPdfBinaryModel): PrintPdfBinaryDto {
    return {
      pdfFile: model.pdfFile,
      printerPath: model.printerPath,
      paperHeightMm: model.paperHeightMm,
      paperWidthMm: model.paperWidthMm,
    };
  }
}
