import { SpectrophotometerConnectionStatusDto } from '../../dtos/spectrophotometer-connection-status/spectrophotometer-connection-status.dto';
import { SpectrophotometerConnectionStatusModel } from '../../models/spectrophotometer-connection-status/spectrophotometer-connection-status.model';

export class SpectrophotometerConnectionStatusCreator {
  public static create(dto: SpectrophotometerConnectionStatusDto): SpectrophotometerConnectionStatusModel {
    return {
      isConnected: dto.connected,
      deviceDetails: {
        model: dto.deviceDetails.DeviceModel,
        serialNumber: dto.deviceDetails.DeviceSerialNumber,
        type: dto.deviceDetails.DeviceType,
      },
    };
  }
}
