import { WebsocketRequestDto } from '../../dtos/websocket-request/websocket-request.dto';
import { WebsocketConnectionParamsModel } from '../../models/websocket-connection-params/websocket-connection-params.model';

export class WebsocketConnectionParamsCreator {
  public static create(dto: WebsocketRequestDto): WebsocketConnectionParamsModel {
    return {
      device: dto.device,
      webSocketPortNumber: dto.webSocketPortNumber,
    };
  }
}
