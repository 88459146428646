import { PrintPdfBase64Dto } from '../../dtos/print-pdf-base64/print-pdf-base64.dto';
import { PrintPdfBase64Model } from '../../models/print-pdf-base64/print-pdf-base64.model';

export class PrintPdfBase64DtoCreator {
  public static create(model: PrintPdfBase64Model): PrintPdfBase64Dto {
    return {
      pdfFile: model.pdfFile,
      printerPath: model.printerPath,
      paperHeightMm: model.paperHeightMm,
      paperWidthMm: model.paperWidthMm,
    };
  }
}
