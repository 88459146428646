import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  NEVER,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';

import { ComDeviceSettingsModel } from '../../../settings/models/com-device-settings/com-device-settings.model';
import { WebsocketProvider } from '../../../shared/providers/websocket/websocket.provider';
import { WebsocketConnectionParamsModel } from '../../models/websocket-connection-params/websocket-connection-params.model';
import { WebsocketDataCreatorModel } from '../../models/websocket-data-creator/websocket-data-creator.model';
import { ConnectorService } from '../connector/connector.service';

export class WebsocketDeviceConnectionService<DeviceReturnDataTypeDto, DeviceReturnDataModel, AllowedCommands = null> {
  public isDeviceConnected$: Observable<boolean>;
  public deviceReadout$: Observable<DeviceReturnDataModel>;
  public isConnectionLoading$: Observable<boolean>;

  private deviceWebsocketSubject$?: WebSocketSubject<DeviceReturnDataTypeDto>;
  private isDeviceConnectedSource$: Subject<boolean>;
  private deviceReadoutSource$: Subject<DeviceReturnDataTypeDto>;
  private websocketConnectionValuesSubscription?: Subscription;
  private isConnectionLoadingSource$: BehaviorSubject<boolean>;

  constructor(
    private getDeviceSettings$: Observable<ComDeviceSettingsModel>,
    private connectorService: ConnectorService,
    private websocketProvider: WebsocketProvider<DeviceReturnDataTypeDto>,
    private dataCreatorClass: WebsocketDataCreatorModel<DeviceReturnDataTypeDto, DeviceReturnDataModel>
  ) {
    this.isDeviceConnectedSource$ = new BehaviorSubject(false);
    this.isDeviceConnected$ = this.isDeviceConnectedSource$.asObservable();

    this.isConnectionLoadingSource$ = new BehaviorSubject<boolean>(false);
    this.isConnectionLoading$ = this.isConnectionLoadingSource$.asObservable().pipe(distinctUntilChanged());

    this.deviceReadoutSource$ = new Subject<DeviceReturnDataTypeDto>();
    this.deviceReadout$ = this.initializeDataReadoutsMapper();
  }

  public initConnection(): void {
    if (this.websocketConnectionValuesSubscription) {
      this.stopWebsocketDeviceConnectionObserver();
    }

    this.isConnectionLoadingSource$.next(true);

    this.websocketConnectionValuesSubscription = this.getDeviceSettings$
      .pipe(
        take(1),
        filter((settings: ComDeviceSettingsModel) => {
          return settings.isEnabled;
        }),
        switchMap((settings: ComDeviceSettingsModel) => this.assignComDevice(settings)),
        switchMap((comPort: string) => this.requestWebsocketConnectionParams(comPort)),
        tap((websocketConnectionParams: WebsocketConnectionParamsModel) => this.assignWebsocketSubject(websocketConnectionParams)),
        switchMap(() => this.deviceWebsocketSubject$ ?? throwError(() => new Error('Websocket connection not initialized'))),
        tap(() => {
          this.isDeviceConnectedSource$.next(true);
          this.isConnectionLoadingSource$.next(false);
        }),
        finalize(() => {
          this.isConnectionLoadingSource$.next(false);

          if (this.deviceWebsocketSubject$ && !this.deviceWebsocketSubject$.closed) {
            this.deviceWebsocketSubject$.unsubscribe();
            this.deviceWebsocketSubject$.complete();
          }
        }),
        catchError(() => this.handleConnectionError())
      )
      .subscribe((measurement: DeviceReturnDataTypeDto) => {
        this.deviceReadoutSource$.next(measurement);
      });
  }

  public sendCommand(command: AllowedCommands): void {
    if (command) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      this.deviceWebsocketSubject$?.next(command as any);
    }
  }

  public stopWebsocketDeviceConnectionObserver(): void {
    this.isDeviceConnectedSource$.next(false);
    this.websocketConnectionValuesSubscription?.unsubscribe();
    this.websocketConnectionValuesSubscription = undefined;
  }

  private handleConnectionError(): Observable<never> {
    this.isDeviceConnectedSource$.next(false);

    return NEVER;
  }

  private assignWebsocketSubject(websocketConnectionParams: WebsocketConnectionParamsModel): void {
    this.deviceWebsocketSubject$ = this.websocketProvider.provide('localhost', websocketConnectionParams.webSocketPortNumber);
  }

  private assignComDevice(settings: ComDeviceSettingsModel): Observable<string> {
    return this.connectorService.assignComDevice(settings.comPort.value, settings.deviceModel.value).pipe(
      map(() => {
        return settings.comPort.value;
      })
    );
  }

  private requestWebsocketConnectionParams(comPort: string): Observable<WebsocketConnectionParamsModel> {
    return this.connectorService.requestWebsocketConnectionParams(comPort);
  }

  private initializeDataReadoutsMapper(): Observable<DeviceReturnDataModel> {
    return this.deviceReadoutSource$.asObservable().pipe(map((data: DeviceReturnDataTypeDto) => this.dataCreatorClass.create(data)));
  }
}
