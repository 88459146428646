import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConnectorUrlUtil } from '../../../core/utils/connector-url/connector-url.util';
import { StationIdDto } from '../../dtos/station-id/station-id.dto';

@Injectable({
  providedIn: 'root',
})
export class ConnectorHttpService {
  constructor(private httpClient: HttpClient) {}

  public getVersion(): Observable<string> {
    return this.httpClient.get(`${ConnectorUrlUtil.getConnectorUrl('version')}`, { responseType: 'text' });
  }

  public getStationId(): Observable<StationIdDto> {
    return this.httpClient.get<StationIdDto>(`${ConnectorUrlUtil.getConnectorUrl('id')}`);
  }
}
