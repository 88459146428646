import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ConnectorAssignedComDeviceDtoCreator } from '../../creators/assign-com-device-dto/assign-com-device-dto.creator';
import { AssignedComDeviceCreator } from '../../creators/assigned-com-device/assigned-com-device.creator';
import { PrintPdfBase64DtoCreator } from '../../creators/print-pdf-base64-dto/print-pdf-base64-dto.creator';
import { PrintPdfBinaryDtoCreator } from '../../creators/print-pdf-binary-dto/print-pdf-binary-dto.creator';
import { PrinterListItemCreator } from '../../creators/printer-list-item/printer-list-item.creator';
import { SpectrophotometerConnectionStatusCreator } from '../../creators/spectrophotometer-connection-status/spectrophotometer-connection-status.creator';
import { SpectrophotometerMeasurementCreator } from '../../creators/spectrophotometer-measurement/spectrophotometer-measurement.creator';
import { StationIdCreator } from '../../creators/station-id/station-id.creator';
import { SupportedComDeviceTypeCreator } from '../../creators/supported-com-device/supported-com-device.creator';
import { WebsocketConnectionParamsCreator } from '../../creators/websocket-connection-params/websocket-connection-params.creator';
import { AssignedComDeviceListDto } from '../../dtos/assigned-com-device-list/assigned-com-device-list.dto';
import { AssignedDeviceDto } from '../../dtos/assigned-com-device/assigned-com-device.dto';
import { PrintPdfBase64Dto } from '../../dtos/print-pdf-base64/print-pdf-base64.dto';
import { PrintPdfBinaryDto } from '../../dtos/print-pdf-binary/print-pdf-binary.dto';
import { PrinterListItemDto } from '../../dtos/printer-list-item/printer-list-item.dto';
import { SpectrophotometerConnectionStatusDto } from '../../dtos/spectrophotometer-connection-status/spectrophotometer-connection-status.dto';
import { SpectrophotometerMeasurementDto } from '../../dtos/spectrophotometer-measurement/spectrophotometer-measurement.dto';
import { StationIdDto } from '../../dtos/station-id/station-id.dto';
import { SupportedComDeviceTypeDto } from '../../dtos/supported-com-device-type/supported-com-device-type.dto';
import { WebsocketRequestDto } from '../../dtos/websocket-request/websocket-request.dto';
import { AssignedComDeviceModel } from '../../models/assigned-com-device/assigned-com-device.model';
import { PrintPdfBase64Model } from '../../models/print-pdf-base64/print-pdf-base64.model';
import { PrintPdfBinaryModel } from '../../models/print-pdf-binary/print-pdf.model';
import { PrinterListItemModel } from '../../models/printer-list-item/printer-list-item.model';
import { SpectrophotometerConnectionStatusModel } from '../../models/spectrophotometer-connection-status/spectrophotometer-connection-status.model';
import { SpectrophotometerMeasurementModel } from '../../models/spectrophotometer-measurement/spectrophotometer-measurement.model';
import { SpectrophotometerTypeModel } from '../../models/spectrophotometer-type/spectrophotometer-type.model';
import { StationIdModel } from '../../models/station-id/station-id.model';
import { SupportedComDeviceTypeNamesModel } from '../../models/supported-com-device-type-names/supported-com-device-type-names.model';
import { SupportedDeviceNameModel } from '../../models/supported-device-name/supported-device-name.model';
import { WebsocketConnectionParamsModel } from '../../models/websocket-connection-params/websocket-connection-params.model';
import { ComPortHttpService } from '../com-port-http/com-port-http.service';
import { ConnectorHttpService } from '../connector-http/connector-http.service';
import { PrinterHttpService } from '../printer-http/printer-http.service';
import { SpectrophotometerHttpService } from '../spectrophotometer-http/spectrophotometer-http.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorService {
  constructor(
    private connectorHttpService: ConnectorHttpService,
    private comPortHttpService: ComPortHttpService,
    private printerHttpService: PrinterHttpService,
    private spectrophotometerHttpService: SpectrophotometerHttpService
  ) {}

  public getVersion(): Observable<string> {
    return this.connectorHttpService.getVersion();
  }

  public getStationId(): Observable<StationIdModel> {
    return this.connectorHttpService.getStationId().pipe(map((dto: StationIdDto) => StationIdCreator.create(dto)));
  }

  public getComPortList(): Observable<Array<string>> {
    return this.comPortHttpService.listComPorts();
  }

  public getSupportedComDevicesList(): Observable<Array<SupportedComDeviceTypeNamesModel>> {
    return this.comPortHttpService.supportedComDevicesList().pipe(
      map((supportedDevicesList: Array<SupportedComDeviceTypeDto>) => {
        return supportedDevicesList.map((supportedDevices: SupportedComDeviceTypeDto) => {
          return SupportedComDeviceTypeCreator.create(supportedDevices);
        });
      })
    );
  }

  public assignComDevice(comPort: string, comDevice: SupportedDeviceNameModel): Observable<void> {
    const dto: AssignedComDeviceListDto = ConnectorAssignedComDeviceDtoCreator.create(comPort, comDevice);

    return this.comPortHttpService.assignComDevice(dto);
  }

  public getAssignedComDevicesList(): Observable<Array<AssignedComDeviceModel>> {
    return this.comPortHttpService.getAssignedComDevicesList().pipe(
      map((dto: AssignedComDeviceListDto) => {
        return dto.configuration.map((configuration: AssignedDeviceDto) => {
          return AssignedComDeviceCreator.create(configuration);
        });
      })
    );
  }

  public requestWebsocketConnectionParams(comPort: string): Observable<WebsocketConnectionParamsModel> {
    return this.comPortHttpService.requestWebsocketStream(comPort).pipe(
      map((connectionParams: WebsocketRequestDto) => {
        return WebsocketConnectionParamsCreator.create(connectionParams);
      })
    );
  }

  public getPrinterList(): Observable<Array<PrinterListItemModel>> {
    return this.printerHttpService.listPrinters().pipe(
      map((dto: Array<PrinterListItemDto>) => {
        return dto.map((listItemDto: PrinterListItemDto) => {
          return PrinterListItemCreator.create(listItemDto);
        });
      })
    );
  }

  public printPdfBinary(printPdfBinaryModel: PrintPdfBinaryModel): Observable<void> {
    const dto: PrintPdfBinaryDto = PrintPdfBinaryDtoCreator.create(printPdfBinaryModel);

    return this.printerHttpService.printPdfBinary(dto);
  }

  public printPdfBase64(printPdfBase64Model: PrintPdfBase64Model): Observable<void> {
    const dto: PrintPdfBase64Dto = PrintPdfBase64DtoCreator.create(printPdfBase64Model);

    return this.printerHttpService.printPdfBase64(dto);
  }

  public checkSpectrophotometerConnection(device: SpectrophotometerTypeModel): Observable<SpectrophotometerConnectionStatusModel> {
    return this.spectrophotometerHttpService.checkConnection(device).pipe(
      map((dto: SpectrophotometerConnectionStatusDto) => {
        return SpectrophotometerConnectionStatusCreator.create(dto);
      })
    );
  }

  public getSpectrophotometerMeasurements(device: SpectrophotometerTypeModel): Observable<Array<SpectrophotometerMeasurementModel>> {
    return this.spectrophotometerHttpService.getMeasurements(device).pipe(
      map((dto: Array<SpectrophotometerMeasurementDto>) => {
        return dto.map((singleMeasurementDto: SpectrophotometerMeasurementDto) =>
          SpectrophotometerMeasurementCreator.create(singleMeasurementDto)
        );
      })
    );
  }

  public clearSpectrophotometerMeasurements(device: SpectrophotometerTypeModel): Observable<void> {
    return this.spectrophotometerHttpService.clearMeasurements(device);
  }
}
