import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConnectorUrlGroupEnum } from '../../../core/enums/connector-group/connector-group.enum';
import { ConnectorUrlUtil } from '../../../core/utils/connector-url/connector-url.util';
import { PrintPdfBase64Dto } from '../../dtos/print-pdf-base64/print-pdf-base64.dto';
import { PrintPdfBinaryDto } from '../../dtos/print-pdf-binary/print-pdf-binary.dto';
import { PrinterListItemDto } from '../../dtos/printer-list-item/printer-list-item.dto';

@Injectable({
  providedIn: 'root',
})
export class PrinterHttpService {
  constructor(private httpClient: HttpClient) {}

  public listPrinters(): Observable<Array<PrinterListItemDto>> {
    return this.httpClient.get<Array<PrinterListItemDto>>(`${ConnectorUrlUtil.getConnectorUrl('pdf', ConnectorUrlGroupEnum.printers)}`);
  }

  public printPdfBinary(dto: PrintPdfBinaryDto): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('PdfFile', dto.pdfFile);
    formData.append('PrinterPath', dto.printerPath);
    formData.append('PaperWidthMm ', dto.paperWidthMm.toString());
    formData.append('PaperHeightMm', dto.paperHeightMm.toString());

    return this.httpClient.post<void>(`${ConnectorUrlUtil.getConnectorUrl('pdf/print', ConnectorUrlGroupEnum.printers)}`, formData);
  }

  public printPdfBase64(dto: PrintPdfBase64Dto): Observable<void> {
    return this.httpClient.post<void>(`${ConnectorUrlUtil.getConnectorUrl('pdf/print_base64', ConnectorUrlGroupEnum.printers)}`, dto);
  }
}
